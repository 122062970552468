import React from 'react';
import ReactDOM from 'react-dom/client';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme/ThemeProvider';
import { StepperProvider } from './components/Stepper/stepperContext';
import appConfig from './config/appConfig.json';

import './theme/fonts/Checkout-Regular.ttf';
import './theme/fonts/Checkout-Bold.ttf';
import './theme/fonts/Checkout-Medium.ttf';
import './theme/fonts/fonts.css';

const isPayPal = String(appConfig.processor) === 'PAYPAL';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <StepperProvider>
        {isPayPal ? (
          <PayPalScriptProvider
            options={{
              'client-id': appConfig?.publicKey,
              components: 'buttons',
              currency: appConfig?.currency,
            }}
          >
            <App />
          </PayPalScriptProvider>
        ) : (
          <App />
        )}
      </StepperProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();
