import axios from 'axios';
import rootConfig from '../config';
import appConfig from '../config/appConfig.json';

const createClient = (configs) => {
  const id = appConfig?.applicationId;
  const {
    requestInterceptors = () => [],
    responseInterceptors = () => [],
    ...axiosConfigs
  } = configs;
  const http = axios.create(axiosConfigs);

  http.interceptors.request.use((config) => {
    const configuration = config;
    configuration.headers['Application-Id'] = id;
    return configuration;
  });

  requestInterceptors(http).forEach((interceptor) =>
    http.interceptors.request.use(...interceptor),
  );
  responseInterceptors(http).forEach((interceptor) =>
    http.interceptors.response.use(...interceptor),
  );

  return http;
};

const client = createClient({
  ...rootConfig.http,
  responseInterceptors() {
    return [
      [
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          return Promise.reject(error);
        },
      ],
    ];
  },
});

export default client;
