import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import client from './client';

export default function InjectAxiosInterceptors() {
  const navigate = useNavigate();

  useEffect(() => {
    client.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        const error = err.response;

        if (error?.status === 403 || error?.status === 404) {
          navigate('/not-found');
          return null;
        }
        return Promise.reject(err);
      },
    );
  }, [navigate]);

  return null;
}
