const http = { baseURL: process.env.REACT_APP_API_BASE_URL, timeout: 14000 };

const config = {
  http,
  site: process.env.REACT_APP_SITE_BASE_URL,
  mp_public_key: process.env.MP_PUBLIC_KEY,
  mp_access_token: process.env.MP_ACCESS_TOKEN,
};

export default config;
