import React from 'react';
import { node } from 'prop-types';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import preset from './preset';
import Normalize from '../components/Normalize/Normalize';

const propTypes = {
  children: node.isRequired,
};

const theme = {
  ...preset,
  fonts: {
    ...preset.fonts,
  },
};

function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <Normalize />
      {children}
    </EmotionThemeProvider>
  );
}

ThemeProvider.propTypes = propTypes;

export default ThemeProvider;
