import appConfig from '../../config/appConfig.json';

const getColors = appConfig?.colors;
const overrides = getColors?.overrides || {};

const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  default: '#E0E1E2',
  defaultText: '#00000099',
  primary: getColors?.primary || '#F83759',
  primaryAccent: getColors?.primaryAccent || '#F83759',
  secondary: getColors?.secondary || '#6A58F4',
  secondaryAccent: getColors?.secondaryAccent || '#F83759',
  darkGrey: '#241C43',
  blue: '#0052C3',
  darkBlue: '#00275D',
  lightBlue: '#EAF1FA',
  blueHighlight: '#CCDDF3',
  lightGrey: '#FAFAFC',
  grey: '#222831',
  text: '#222222',
  lightText: '#B5B5B5',
  disabledText: '#B0B0B0',
  disabledBackground: '#F9F9F9',
  green: '#21BA45',
  success: '#1E561FCC',
  successLight: '#FCFFF5',
  warning: '#7A4D05CC',
  warningLight: '#FFFAF3',
  error: '#dc3545',
  errorLight: '#FFF6F6',
  inputHighlight: '#3D80FC',
  info: '#17a2b8',
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    50: '#f9f9f9',
    100: '#f2f2f2',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#323232',
  },
  overrides,
};

export default colors;
