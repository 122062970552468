import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_STEPS,
} from './constants';

export const defaultStepperState = {
  allSteps: [],
  currentStep: 0,
};

// eslint-disable-next-line default-param-last
export const reducer = (state = defaultStepperState, action) => {
  const { currentStep, allSteps } = state;
  const { type, payload } = action;
  switch (type) {
    case SET_STEPS:
      return {
        ...state,
        allSteps: payload.allSteps,
      };
    case INCREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep:
          currentStep < allSteps.length - 1 ? currentStep + 1 : currentStep,
      };
    case DECREMENT_CURRENT_STEP:
      return {
        ...state,
        currentStep: currentStep > 0 ? currentStep - 1 : currentStep,
      };

    default:
      return state;
  }
};
