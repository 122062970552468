import React from 'react';
import { Global, css } from '@emotion/react';

const styles = (theme) =>
  css({
    html: {
      webkitFontSmoothing: 'antialiased',
      mozOsxFontSmoothing: 'grayscale',
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      fontFamily: theme.fonts.body,
      fontWeight: theme.fontWeights.body,
      color: theme.colors.text,
      backgroundColor: theme.colors.white,
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
    },
    'strong, b': {
      fontWeight: theme.fontWeights.bold,
    },
  });

function Normalize() {
  return <Global styles={styles} />;
}

Normalize.displayName = 'Normalize';

export default Normalize;
