import styled from '@emotion/styled';
import memoize from '@emotion/memoize';
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  compose,
} from 'styled-system';
import css from '@styled-system/css';
import systemForwardProp from '@styled-system/should-forward-prop';

export const classesStyles = (prop) => (props) => {
  const { classes = {} } = props;
  const styles = classes[prop];

  return typeof styles === 'function' ? css(styles(props)) : css(styles);
};

export const system = () =>
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
  );

const allowedProps = ['activeClassName', 'exact'];

const styledBox =
  (element = 'div', config = {}) =>
  (...args) => {
    const { shouldForwardProp = () => true } = config;

    return styled(element, {
      shouldForwardProp: memoize(
        (prop) =>
          allowedProps.includes(prop) ||
          (systemForwardProp(prop) && shouldForwardProp(prop)),
      ),
    })(...args, system());
  };

export default styledBox;
