import React, { useReducer, useContext, useCallback } from 'react';
import { node } from 'prop-types';
import { defaultStepperState, reducer } from './stepperStore';
import {
  DECREMENT_CURRENT_STEP,
  INCREMENT_CURRENT_STEP,
  SET_STEPS,
} from './constants';

const propTypes = {
  children: node.isRequired,
};

export const StepperContext = React.createContext();

export function StepperProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, defaultStepperState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StepperContext.Provider value={[state, dispatch]}>
      {children}
    </StepperContext.Provider>
  );
}

StepperProvider.propTypes = propTypes;

export const useStepper = () => {
  const [state, dispatch] = useContext(StepperContext);
  const { currentStep, allSteps } = state;

  if (!StepperContext) {
    throw new Error('useStepper should be used inside StepperProvider');
  }

  const incrementCurrentStep = useCallback(() => {
    dispatch({
      type: INCREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const decrementCurrentStep = useCallback(() => {
    dispatch({
      type: DECREMENT_CURRENT_STEP,
    });
  }, [dispatch]);

  const setSteps = useCallback(
    (steps) => dispatch({ type: SET_STEPS, payload: { allSteps: steps } }),
    [dispatch],
  );

  return {
    incrementCurrentStep,
    decrementCurrentStep,
    setSteps,
    currentStep,
    allSteps,
  };
};
