import React, { forwardRef } from 'react';
import { shape, oneOfType, func, oneOf, number, string } from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import isPropValid from '@emotion/is-prop-valid';
import { keyframes } from '@emotion/react';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import styledBox, { classesStyles } from '../../utils/system';

const variants = {
  indeterminate: 'indeterminate',
  determinate: 'determinate',
};

const sizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
};

const propTypes = {
  /**
   * The color of the component. It supports those theme colors and custom colors.
   */
  color: string,
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: number,
  /**
   * There are two ways you can increase the height of the progress bar, using
   * this prop or `height` supplied by styled-system.
   */
  size: oneOf(Object.values(sizes)),
  /**
   * The variant to use.
   */
  variant: oneOf(Object.values(variants)),
  /**
   * Override or extend the styles applied to the components.
   */
  classes: shape({
    root: oneOfType([shape({}), func]),
    bar: oneOfType([shape({}), func]),
  }),
};

const defaultProps = {
  value: 0,
  size: sizes.md,
  color: 'primary.main',
  variant: variants.indeterminate,
  classes: {
    root: null,
    bar: null,
  },
};

const ProgressBar = styledBox('div')(
  { label: 'ProgressBar' },
  (props) => {
    const bg = themeGet(`colors.${props.cBg}`, props.cBg)(props);

    return css({
      bg,
      width: '100%',
      display: 'block',
      color: props.cBg,
      overflow: 'hidden',
      position: 'relative',
    });
  },
  variant({
    prop: 'cSize',
    scale: 'progressBar',
    variants: {
      [sizes.sm]: { height: 1 },
      [sizes.md]: { height: 2 },
      [sizes.lg]: { height: 3 },
    },
  }),
  classesStyles('root'),
);

const indeterminate = keyframes`
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
`;

const indeterminateShort = keyframes`
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
`;

const Bar = styled('div', {
  shouldForwardProp: (prop) =>
    isPropValid(prop) && !['value', 'classes'].includes(prop),
})(
  css({
    backgroundColor: 'currentColor',
  }),
  ({ value: width }) =>
    variant({
      prop: 'variant',
      scale: 'progressBar',
      variants: {
        [variants.determinate]: {
          top: 0,
          left: 0,
          bottom: 0,
          width: `${width}%`,
          position: 'absolute',
          transition: 'width .3s linear',
        },
        [variants.indeterminate]: {
          '&:before, &:after': {
            content: "''",
            position: 'absolute',
            bg: 'inherit',
            top: 0,
            left: 0,
            bottom: 0,
            willChange: 'left, right',
          },
          '&:before': {
            animation: `${indeterminate} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite`,
          },
          '&:after': {
            animation: `${indeterminateShort} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite`,
            animationDelay: '1.15s',
          },
        },
      },
    }),
  classesStyles('bar'),
);

const Component = forwardRef(({ value, color, size, ...props }, ref) => (
  <ProgressBar ref={ref} {...props} cBg={color} cSize={size} role="progressbar">
    <Bar classes={props.classes} variant={props.variant} value={value} />
  </ProgressBar>
));

Component.displayName = 'ProgressBar';
Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
