const breakpoints = (
  points = {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
) => {
  const scale = Object.values(points);
  const mq = {
    up(breakpoint) {
      const width = points[breakpoint] || breakpoint;
      return `@media screen and (min-width: ${width})`;
    },
    down(breakpoint) {
      const width = points[breakpoint] || breakpoint;
      return `@media screen and (max-width: ${width})`;
    },
    between(breakpoint1, breakpoint2) {
      const width1 = points[breakpoint1] || breakpoint1;
      const width2 = points[breakpoint2] || breakpoint2;
      return `@media screen and (min-width: ${width1}) and (max-width: ${width2})`;
    },
  };

  Object.keys(points).forEach((key) => {
    scale[key] = points[key];
    mq[key] = `@media screen and (min-width: ${points[key]})`;
  });

  return {
    breakpoints: scale,
    mq,
  };
};

export default breakpoints;
